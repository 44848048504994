@import '~@livechat/design-system/dist/design-system.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}
.App {
  text-align: center;
  width: 90%;
  margin-left:5%;
}

.component__wrapper {
  box-shadow: 0 1px 10px 0 rgba(66, 77, 87, 0.8);
  border-radius: 4px;
  max-height: 400px;
  margin-bottom: 16px;
  max-width: 400px;
  padding: 8px;
  background-color: white;
  width:100%;
}
.flex--row--space-around{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.app__wrapper {
  margin:16px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
}

.component__wrapper h3 {
  text-align: center;
}
.loader__container{
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.h--480{
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

.h--140 {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tasks-list__empty-state--placeholder{
  max-width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #767680;
  font-style: italic;
  width: 100%;
}

.table__row td{
  border-bottom: 1px solid #E2E2E4
}

.tasks-list__item__wrapper{
  max-width: 382px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #E2E2E4;
}
.tasks-list__item__wrapper:hover {
  background-color: #e1e9ec;
  cursor: pointer;
}

.tasks-list__item__container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.tasks-list__item__title{
  width: 60%;
  font-size: 15px;
  line-height: 15px;
  margin-left: 8px;
}

.tasks-list__item__body{
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
}

.tasks-list__item__details-container{
  min-width: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
}

.flex--column{
  display: flex;
  flex-direction: column;
}

.authenticate-button__container{
  padding:16px;
  height: 100px;
  justify-content: space-around;
}

.input-width--90p{
  min-width: 300px;
}

.modal-form--date-container{
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 15px;
}

.cursor__pointer:hover{
  cursor: pointer;
}

.modal{
  width: 100%;
  max-height: 450px;
  max-width: 350px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.margin--bottom--20{
  margin-bottom: 8px;
  margin-top:20px;
}
